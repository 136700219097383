import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import { camelCase, upperFirst } from "lodash/fp";
import React, { useEffect, useContext, useState } from "react";
import adminContext from "@whitespace/gatsby-theme-wordpress-basic/src/contexts/adminContext";
import ReactDOM from "react-dom";

import * as moduleComponents from "./modularity-modules";

function fromContentTypeToComponentName(contentTypeName) {
  return (
    contentTypeName &&
    upperFirst(camelCase(contentTypeName.replace(/^mod-/, ""))) + "Module"
  );
}

const EditButton = ({ editLink }) => (
  <a
    href={editLink}
    className="edit-hover-btn"
    style={{
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "#000",
      color: "#fff",
      padding: "10px 16px",
      borderRadius: "6px",
      zIndex: 10000,
      pointerEvents: "auto",
      fontSize: "14px",
      textDecoration: "none",
      cursor: "pointer",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    }}
  >
    Redigera modul
  </a>
);

export default function ModuleController({ module }) {
  const { user } = useContext(adminContext);
  const [hoveredTarget, setHoveredTarget] = useState(null);

  const handleMouseOver = (event) => {
    const target = event.target.closest("[data-id]");

    if (!user || !target || target.querySelector(".edit-hover-btn")) return;

    if (getComputedStyle(target).position === "static") {
      target.style.position = "relative";
    }

    setHoveredTarget(target);
  };

  const handleMouseOut = (event) => {
    const target = event.target.closest("[data-id]");
    const relatedTarget = event.relatedTarget;

    if (
      target &&
      (!relatedTarget || !target.contains(relatedTarget)) &&
      target.querySelector(".edit-hover-btn")
    ) {
      setHoveredTarget(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseover", handleMouseOver);
    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
      document.removeEventListener("mouseout", handleMouseOut);
    };
  }, [user]);

  useEffect(() => {
    if (hoveredTarget && user) {
      const postId = hoveredTarget.getAttribute("data-id");
      const editLink = `${process.env.GATSBY_WORDPRESS_URL}/wp-admin/post.php?post=${postId}&action=edit`;

      const container = document.createElement("div");
      hoveredTarget.appendChild(container);

      ReactDOM.render(<EditButton editLink={editLink} />, container);

      return () => {
        ReactDOM.unmountComponentAtNode(container);
        hoveredTarget.removeChild(container);
      };
    }
  }, [hoveredTarget, user]);

  const moduleType = module?.contentType?.node?.name;
  const { processPageContent } = useHTMLProcessor();
  let componentName = fromContentTypeToComponentName(moduleType);
  let Component =
    // eslint-disable-next-line import/namespace
    (componentName && moduleComponents[componentName]) ||
    // eslint-disable-next-line import/namespace
    moduleComponents.FallbackModule;

  // TODO: Deprecate `modDescription` in favor of `content`
  let {
    content,
    headingContent: heading,
    headingLevel,
  } = module?.modDescription?.description
    ? processPageContent(module.modDescription.description, {
        extractHeading:
          !!module.hideTitle && !Component.wsuiConfig?.leaveHeading,
        leavePreamble: true,
      })
    : module?.content
    ? processPageContent(module.content, {
        extractHeading:
          !!module.hideTitle && !Component.wsuiConfig?.leaveHeading,
        leavePreamble: true,
        contentMedia: module.contentMedia,
        contentModularityModules: module.contentModularityModules,
        semanticHeadings: true,
        ensureHeadingIds: `module-${module.databaseId}-heading`,
      })
    : {};
  let description = content;
  return (
    <div data-id={module.databaseId} className="editable-module">
      <Component
        module={module}
        titleIcon={module.commonModuleSettings?.titleIcon}
        title={module.hideTitle ? heading : module.title}
        headingVariant={headingLevel ? `h${headingLevel}` : undefined}
        description={description}
      />
    </div>
  );
}
